
export interface MenuRoute {
	text: string;
	disabled: Boolean;
	href: string;
	roles: string[];
	open?: Boolean;
	children?: MenuRoute[];
	dataCy?: string;
}

export class MenuRouteEntity implements MenuRoute {
	text: string = "";
	disabled: Boolean = false;
	href: string = "#";
	roles: string[] = [];
	open?: Boolean = false;
	children?: MenuRoute[] = [];
	dataCy?: string = "";

	constructor(param: MenuRoute) {
		this.text = param.text;
		this.disabled = param.disabled;
		this.href = param.href ?? "#";
		this.roles = param.roles;
		this.open = param.open;
		this.children = param.children;
		this.dataCy = param.dataCy;
	}

	addChildren(child: MenuRoute) {
		this.children?.push(child);
	}
}

export function prepareMenu(): MenuRoute[] {
	return getItemsMenu();
}

export function getItemsMenu() {
	return [
		{
			text: "menu.personsMenu.title",
			disabled: false,
			href: "#",
			roles: ["account-admin", "account-operator", "account-personas"],
			open: false,
			children: [
				{
					text: "menu.personsMenu.personsTitle",
					disabled: false,
					href: "/admin/persons/index",
					roles: [
						"account-admin",
						"account-operator",
						"account-personas",
					],
					open: false,
				},
				{
					text: "menu.personsMenu.privatePOIsTitle",
					disabled: false,
					href: "/admin/privatepois/create",
					roles: [
						"account-admin",
						"account-operator",
						"account-personas",
					],
					open: false,
				},
			] as MenuRoute[],
		} as MenuRoute,
		{
			text: "menu.advertiser",
			disabled: false,
			href: "/admin/advertisers/index",
			roles: ["account-admin", "account-operator", "account-report"],
			dataCy: "menu-advertiser",
		} as MenuRoute,
		{
			text: "menu.campaign",
			disabled: false,
			href: "/admin/campaigns/index",
			roles: ["account-admin", "account-operator", "account-report"],
			dataCy: "menu-campaign",
		} as MenuRoute,
		{
			text: "menu.lineItem",
			disabled: false,
			href: "/admin/lineitem/index",
			roles: ["account-admin", "account-operator", "account-report"],
		} as MenuRoute,
		{
			text: "menu.creative",
			disabled: false,
			href: "/admin/creatives/index",
			roles: ["account-admin", "account-operator", "account-report"],
		} as MenuRoute,
		{
			text: "menu.reporting.title",
			disabled: false,
			href: "#",
			roles: ["account-admin", "account-operator", "account-report"],
			open: false,
			children: [
				{
					text: "menu.reporting.direct",
					disabled: false,
					href: "/admin/reporting/index",
					roles: [
						"account-admin",
						"account-operator",
						"account-report",
					],
					open: false,
				},
				{
					text: "menu.reporting.onDemand",
					disabled: false,
					href: "/admin/reportingOnDemand/index",
					roles: [
						"account-admin",
						"account-operator",
						"account-report",
					],
					open: false,
				},
				{
					text: "menu.reporting.report_all_beta",
					disabled: false,
					href: "/admin/reporting/index_report_all",
					roles: [
						"account-admin",
						"account-operator",
						"account-report",
					],
					open: false,
				},
				{
					text: "menu.reporting.pdf_reports",
					disabled: false,
					href: "/admin/reporting/pdf_reports",
					roles: [
						"account-admin",
						"account-operator",
						"account-report",
					],
					open: false,
				},
			] as MenuRoute[],
		},
		{
			text: "menu.dashboard",
			disabled: false,
			href: "/admin/dashboard",
			roles: ["account-admin", "account-operator", "account-report"],
		} as MenuRoute,
		{
			text: "menu.requestCampaign",
			disabled: false,
			href: "/admin/requestCampaign/index",
			roles: ["account-admin", "account-operator"],
		} as MenuRoute,
		{
			text: "menu.tools.title",
			disabled: false,
			href: "#",
			roles: ["account-admin", "account-operator"],
			open: false,
			children: [
				{
					text: "menu.tools.event",
					disabled: true,
					href: "/admin/events/index",
					roles: [],
					open: false,
					// roles: ["account-admin"] dejar habilitado solo cuando este terminado
				},
				{
					text: "menu.tools.list",
					disabled: false,
					href: "/admin/custom_lists/index",
					roles: ["account-admin", "account-operator"],
					open: false,
				},
				{
					text: "menu.tools.events",
					disabled: false,
					href: "/admin/events/index",
					roles: ["account-admin", "account-operator"],
					open: false,
				},
				{
					text: "menu.tools.modifier",
					disabled: false,
					href: "/admin/modifiers/index",
					//roles: []
					roles: ["account-admin", "account-operator"],
					open: false,
				},
				{
					text: "menu.tools.model",
					disabled: false,
					href: "/admin/models/index",
					roles: ["account-admin", "account-operator"],
					open: false,
				},
				{
					text: "menu.tools.segment",
					disabled: false,
					href: "",
					roles: ["account-admin", "account-operator"],
					// roles: ["account-admin"] dejar habilitado solo cuando este terminado
					open: false,
					children: [
						{
							text: "menu.tools.lookalike",
							disabled: false,
							href: "/admin/lookalike/index",
							roles: [],
							open: false,
						},
						{
							text: "menu.tools.segment_sub",
							disabled: false,
							href: "/admin/segments/index",
							roles: ["account-admin", "account-operator"],
							open: false,
						},
						{
							text: "menu.tools.segment_category",
							disabled: false,
							href: "/admin/segmentCategory/create",
							roles: ["account-admin", "account-operator"],
							open: false,
						},
						{
							text: "menu.tools.segment_upload",
							disabled: false,
							href: "/admin/segmentUpload/create",
							roles: ["account-admin", "account-operator"],
							open: false,
						},
					],
				},
				{
					text: "oneLink.title",
					disabled: false,
					href: "/admin/toolsRappi/index",
					roles: ["account-admin", "account-operator"],
					open: false,
				},
				{
					text: "menu.tools.user",
					disabled: false,
					href: "/admin/users/index",
					roles: ["account-admin", "account-operator"],
					open: false,
				},
			] as MenuRoute[],
		} as MenuRoute,
	];
}
